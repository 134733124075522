import React, {useEffect, useState} from "react";
import Yup from "yup";

// @mui
import {alpha, useTheme} from '@mui/material/styles';
import { bgGradient } from 'src/theme/css';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { Card } from "@mui/material";
import Divider from "@mui/material/Divider";
import Scrollbar from 'src/components/scrollbar';
import _ from "lodash";

// _mock
import {_appFeatured, _bankingContacts, _bankingCreditCard, _bankingRecentTransitions} from 'src/custom/_mock';
// components

import {
    BookingIllustration,
} from 'src/custom/assets/illustrations';

import { useSettingsContext } from 'src/components/settings';
import useApiQuery from "src/hooks/use-api-query";
import {doRequest} from "src/utils/axios";
import {useParams} from "src/routes/hook";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import WidgetSummary from "../widget-summary";
import RankPoints from "../rank-points";
import WidgetConfig from "../widget-config";

import LevelAnalytic from "../level-Analytic";
import AppCurrentLevels from "../app-current-levels";
import ChargeMoneyIllustration from "../../../assets/illustrations/charge-money";
import MultilevelIllustration from "../../../assets/illustrations/multilevel";
import BusinessAndFinancial from "../../../assets/illustrations/business_and_financial";
import DollarAttraction from "../../../assets/illustrations/dollar-attraction";
import AllMoney from "../../../assets/illustrations/all-money";
import {ConfigFormInterface} from "../../../../lib/pxpForm";
import PxpForm from "../../../../lib/pxpForm/FormPxp";
import BonusCurrentBalance from "../bonus-current-balance";
import CommissionByCountries from "../commission-by-countries";
import {useBoolean} from "../../../../hooks/use-boolean";
import LevelDetail from "../level-detail";
import {useConfirm} from "../../../../hooks/use-confirm";
import {LOGIN_SCREEN_SETTING} from "../../../config-global";

// ----------------------------------------------------------------------
const SPACING = 3;

interface NextRankInterface {
    nivel: number;
    nombre: string;
    volumen_organizacional: number;
}
interface ExtraDataInterface {
    label: string,
    value: string | number
}
interface MyAppLevelsInterface {
    label: string,
    value: number
}
export interface LevelDetailInterface {
    nivel: number;
    porcentaje: number;
    rnum: number;
    total_cv: number;
    total_pv: number;
    generaciones_cv_monto: number;
}
export interface BonusInterface {
    label: string,
    value: number | string
}
interface DataToRenderInterface {
    nextRank: NextRankInterface;
    extraData: ExtraDataInterface[];
    levelsDetail: LevelDetailInterface[];
    myAppLevels: MyAppLevelsInterface[];
    dataByCountries?: any;
    ranks: any;
    dataJson: Record<any, any>;
    monthName: string;
    year: string;
    status: string;
    active:string;
    totalTipoCambio:Record<any, any>;
    bonus: BonusInterface[];
}
interface Interface {
    code?: string
}
export default function PaymentPeriod({code}:Interface) {
    const theme = useTheme();
    const params = useParams();
    const openDetailDialog = useConfirm({
        open: false,
        data: undefined,
    });

    const settings = useSettingsContext();

    const [period, setPeriod] = useState<Record<any, any>>();
    const [dataToRender, setDataToRender] = useState<DataToRenderInterface>();
    const [countrySelectedByPayment, setCountrySelectedByPayment] = useState<any>();
    const [totalByCountry, setTotalByCountry] = useState<any>();
    const {data}: any = useApiQuery(['getPaymentPeriod', period, code],() => doRequest({
        url:'/api/afiliacion-nd/Afiliado/getPaymentPeriod',
        method: 'POST',
        data: {
            ...((params && params.periodId) && {id_periodo:params.periodId }),
            ...((period ) && {id_periodo:period.id_periodo }),
            ...(code && {myNetworkCode: code})
        },
    }), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        staleTime: Infinity
    })
    const periodIdToUse = period?.id_periodo || params.periodId;

    useEffect(() => {
        if(data && data[0]) {
            const {data_json_aux: dataJsonAux, ranks, pv_para_calificar: pvParaCalificar, month_name:monthName, gestion, estado, total_tipo_cambio} : any = data[0];
            const {niveles_a_cobrar: nivelesACobrar,
                sum_por_niveles: sumPorNiveles,
                generaciones_cv_monto: generacionesCvMonto,
                activos,
                inactivos,
                totales,
                bonus_multinivel_sum: bonusMultinivelSum,
                bonus_sum: bonusSum,
                red

            } = dataJsonAux;
            const currentLevelRank = dataJsonAux.current_afiliado.nivel_rango;
            const find = data[0].ranks.find((r:any) => r.nivel === currentLevelRank + 1);
            let levelDetail = _.merge(nivelesACobrar, sumPorNiveles, generacionesCvMonto)
            levelDetail = levelDetail.map((ld:any, index:number) => (
                {
                    ...ld,
                    activos: activos[index] ? activos[index].count : 0,
                    inactivos: inactivos[index] ? inactivos[index].count : 0
                }
            ))
            //const bonus = _.concat(bonusMultinivelSum,bonusSum)
            const combinedArray = bonusMultinivelSum.concat(bonusSum);
            const sumByBonus:any = {};

            combinedArray.forEach((item:any) => {
                const { bonus, sum } = item;
                if (!sumByBonus[bonus]) {
                    sumByBonus[bonus] = 0;
                }
                sumByBonus[bonus] += sum;
            });

            //const myAppLevels = generacionesCvMonto.map(() => );
            setDataToRender({
                nextRank: find,
                extraData: [
                    {label: 'Habilitado', value: dataJsonAux.habilitado},
                    {label: 'CV Propio', value: dataJsonAux.puntos_propio_sum.cv},
                    {label: 'PV Propio', value: dataJsonAux.puntos_propio_sum.pv},
                    {label: 'PV Calificar', value: dataJsonAux.pv_para_calificar},
                    {label: 'Califica?', value: dataJsonAux.sube_nivel?.sube_nivel || 'no'},
                ],
                levelsDetail: levelDetail,
                myAppLevels: [
                    { label: 'RED', value: totales.total_red || 0 },
                    { label: 'BONO INSTANTE', value: totales.total_bono_instante || 0 },
                    { label: 'BONO PATROCINIO', value: totales.total_bono_patrocinio || 0 },
                    { label: 'BONO MENSUAL', value: totales.total_bono_mensual || 0 },
                ],
                ...(((dataJsonAux.generaciones_cv_monto_a_cobrar_agrupado_por_nivel_pais && dataJsonAux.generaciones_cv_monto_a_cobrar_agrupado_por_nivel_pais.length > 0) || (dataJsonAux.bono_patrocinio_por_pais && dataJsonAux.bono_patrocinio_por_pais.length > 0)) && {
                    dataByCountries: _.mergeWith(
                        dataJsonAux.generaciones_cv_monto_a_cobrar_agrupado_por_nivel_pais,
                        dataJsonAux.bono_patrocinio_por_pais
                    )
                }),
                ranks,
                dataJson: dataJsonAux,
                monthName,
                year: gestion,
                status: estado,
                active: dataJsonAux.habilitado,
                totalTipoCambio: total_tipo_cambio,
                bonus: Object.entries(sumByBonus).map(([nameKey, value]: any) => ({ label: nameKey, value}))
            });
            if(total_tipo_cambio) {
                console.log('total_tipo_cambio',total_tipo_cambio)
                const {usuario_default_pais, paises} = total_tipo_cambio;
                if(usuario_default_pais && paises) {
                    //setCountrySelectedByPayment(total_tipo_cambio.config_pais.find((cp:any) => cp.pais === usuario_default_pais.pais));
                    setCountrySelectedByPayment(usuario_default_pais.pais);
                }

            }

        }
    }, [data]);

    useEffect(() => {
        if(countrySelectedByPayment && dataToRender?.totalTipoCambio) {
            console.log('useEffect countrySelectedByPayment',countrySelectedByPayment)
            console.log('useEffect totalByCountry',totalByCountry)
            if(dataToRender.totalTipoCambio.paises) {
                setTotalByCountry(dataToRender.totalTipoCambio.paises[countrySelectedByPayment])

            }
        }


    }, [countrySelectedByPayment, dataToRender?.totalTipoCambio, totalByCountry]);

    useEffect(() => {
        console.log('useEffect totalByCountry',totalByCountry)
    }, [totalByCountry]);

    const getColor = (num:any) => {
        let color;
        switch (num) {
            case 0:
                color = theme.palette.info.main;
                break;
            case 1:
                color = theme.palette.success.main
                break;
            case 3:
                color = theme.palette.warning.main
                break;
            case 4:
                color = theme.palette.error.main
                break;
            case 5:
                color = theme.palette.text.secondary
                break;
            default:
                color = theme.palette.info.main;
                break;

        }
        return color;
    }

    const changeCountry = (country:string) => {
        console.log('changeCountry', country)
        console.log('changeCountry', dataToRender)
    }

    //config form autocomplete
    const configForm: ConfigFormInterface = {
        name: 'period',
        attributes: {
            periodId:{
                type: 'AutoComplete',
                name: 'periodId',
                label: 'Periodo',
                variant: 'outlined',
                gridForm: {xs: 12, sm: 12},
                store: {
                    axios: {
                        instance: doRequest ,
                        config: {
                            method: "POST",
                            url: "/api/afiliacion-nd/Afiliado/getPeriodoOfinet",
                            data: {
                            }
                        }
                    },
                    dataReader:{
                        dataRows: 'data'
                    },
                    filterConfig:{
                        filterName:"genericFilterFields",
                        filterValue:"genericFilterValue",
                        parFilters:"emailListName"
                    },
                    load: true,
                    minChars : 2,
                    isSearchable:true,
                    idDD:"id_periodo",
                    descDD:"periodo",
                },
                onChange: (change) => {
                    if(change.value) {
                        setPeriod(change.valueOfType)
                        change.states.pais.setValue('');
                    }
                }
            },
            pais: {
                type: 'Dropdown',
                name: 'genero',
                label: 'País a Cobrar',
                size: 'medium',
                variant: 'outlined',
                gridForm: {xs: 12, sm: 6},
                helperText: 'podrían aplicarse comisiones adicionales relacionadas con la conversión de moneda o los costos asociados al envío del dinero',
                options: [
                    {value: '', label: ''},
                    {value: 'BOLIVIA', label: 'BOLIVIA'},
                    {value: 'PERU', label: 'PERU'},
                    {value: 'BRASIL', label: 'BRASIL'},
                ],
                hidden: true,
                onChange: (change) => {
                    console.log('change.value',change.value)
                    if(change.value) {
                        //changeCountry(change.value)
                        setCountrySelectedByPayment(change.value);

                    }
                }


            },
        },
        submit: {
            onSubmit: (states, values) => {
                console.log('values',values)
            },
            submitButton: false,
        },
    };


    const handleViewDetail = (level:any) => {
        const periodIdAux = period?.id_periodo || params.periodId;
        openDetailDialog.addData(level);
        openDetailDialog.onTrue();
    }
    // @ts-ignore
    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <Grid container spacing={SPACING} disableEqualOverflow>
                <Grid xs={12} md={12}>
                    <PxpForm config={configForm} />
                </Grid>
                {
                    !code && (
                        <>
                            {
                                dataToRender?.dataJson.totales_por_pais ? (
                                    <>
                                        {
                                            totalByCountry && (
                                                <>
                                                    {/*<Grid xs={12} md={4}>
                                                        <WidgetSummary
                                                            title={`${dataToRender?.active === 'no' ? 'Potencial de Cobro' : 'Total a Cobrar'} ( ${dataToRender?.monthName} / ${dataToRender?.year} )`}
                                                            {...((dataToRender?.active === 'no') && {description: 'No puedes cobrar, no estás activo' })}
                                                            total={dataToRender ? totalByCountry.totales.total_a_pagar : 0}
                                                            icon={<DollarAttraction />}
                                                            {...((dataToRender && dataToRender.status === 'pagado') && {label: dataToRender.status })}
                                                            label2={dataToRender.totalTipoCambio.config_pais.find((cp:any) => cp.pais === countrySelectedByPayment).tipo_moneda}
                                                            //label="A COBRAR"
                                                            sx={{
                                                                ...(dataToRender?.active === 'no' ? {
                                                                    ...bgGradient({
                                                                        direction: '135deg',
                                                                        startColor: alpha(theme.palette.error.light, 0.2),
                                                                        endColor: alpha(theme.palette.error.main, 0.2),
                                                                    }),
                                                                } : {
                                                                    ...bgGradient({
                                                                        direction: '135deg',
                                                                        startColor: alpha(theme.palette.success.light, 0.2),
                                                                        endColor: alpha(theme.palette.success.main, 0.2),
                                                                    }),
                                                                })

                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid xs={12} md={4}>
                                                        <WidgetSummary
                                                            title="Total Red"
                                                            total={totalByCountry.totales.total_red || 0}
                                                            icon={<BusinessAndFinancial />}
                                                            label2={dataToRender.totalTipoCambio.config_pais.find((cp:any) => cp.pais === countrySelectedByPayment).tipo_moneda}

                                                        />
                                                    </Grid>
                                                    <Grid xs={12} md={4}>
                                                        <WidgetSummary
                                                            title="Total Ganado"
                                                            total={totalByCountry.totales.total_ganado || 0}
                                                            icon={<AllMoney />}
                                                            label2={dataToRender.totalTipoCambio.config_pais.find((cp:any) => cp.pais === countrySelectedByPayment).tipo_moneda}

                                                        />
                                                    </Grid>*/}

                                                    <Grid xs={12} md={12}>
                                                        <Alert severity="info" sx={{ mb: 3 }}>
                                                            Atención: Los bonos y regalías se generan según el país asociado al punto de venta donde se registraron. Actualmente, estamos realizando cambios en el sistema. Por favor, tome en cuenta únicamente las Regalías por País hasta que se completen las actualizaciones.
                                                        </Alert>
                                                    </Grid>
                                                    <Grid xs={12} md={12}>
                                                        <CommissionByCountries
                                                            totalByCountry={totalByCountry}
                                                            data={dataToRender ? dataToRender.dataJson.totales_por_pais : undefined}
                                                            {...((dataToRender && dataToRender.status === 'pagado') && {estado: dataToRender.status })}
                                                        />
                                                    </Grid>

                                                </>
                                            )
                                        }


                                    </>
                                ) : (
                                    <>
                                        <Grid xs={12} md={4}>
                                            <WidgetSummary
                                                title={`${dataToRender?.active === 'no' ? 'Potencial de Cobro' : 'Total a Cobrar'} ( ${dataToRender?.monthName} / ${dataToRender?.year} )`}
                                                {...((dataToRender?.active === 'no') && {description: 'No puedes cobrar, no estás activo' })}
                                                total={dataToRender ? dataToRender.dataJson.totales.total_a_pagar : 0}
                                                icon={<DollarAttraction />}
                                                {...((dataToRender && dataToRender.status === 'pagado') && {label: dataToRender.status })}
                                                //label="A COBRAR"
                                                sx={{
                                                    ...(dataToRender?.active === 'no' ? {
                                                        ...bgGradient({
                                                            direction: '135deg',
                                                            startColor: alpha(theme.palette.error.light, 0.2),
                                                            endColor: alpha(theme.palette.error.main, 0.2),
                                                        }),
                                                    } : {
                                                        ...bgGradient({
                                                            direction: '135deg',
                                                            startColor: alpha(theme.palette.success.light, 0.2),
                                                            endColor: alpha(theme.palette.success.main, 0.2),
                                                        }),
                                                    })

                                                }}
                                            />
                                        </Grid>

                                        <Grid xs={12} md={4}>
                                            <WidgetSummary
                                                title="Total Red"
                                                total={dataToRender ? dataToRender.dataJson.totales.total_red : 0}
                                                icon={<BusinessAndFinancial />}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                            <WidgetSummary
                                                title="Total Ganado"
                                                total={dataToRender ? dataToRender.dataJson.totales.total_ganado : 0}
                                                icon={<AllMoney />}
                                            />
                                        </Grid>
                                    </>
                                )
                            }

                        </>
                    )
                }

               {/* {
                    !code && dataToRender && dataToRender.dataByCountries && (
                        <Grid container xs={12}>
                            <Grid xs={12} md={12}>
                                <CommissionByCountries data={dataToRender ? dataToRender.dataByCountries : undefined}/>
                            </Grid>
                        </Grid>
                    )
                }*/}


                <Grid container xs={12}>
                    <Grid xs={12} md={4}>
                        <RankPoints
                            chart={{

                            }}
                            total={dataToRender ? dataToRender.dataJson.pv_para_calificar: 0}
                            goal={dataToRender ? dataToRender.nextRank.volumen_organizacional : 0}
                            data={dataToRender ? dataToRender.ranks : []}
                        />
                    </Grid>
                    <Grid container xs={12} md={4} lg={4}>
                        <Grid xs={12} md={12} lg={12}>
                            <WidgetConfig title="Configuraciones" list={dataToRender ? dataToRender.extraData : []} />
                        </Grid>
                        <Grid xs={12} md={12} lg={12}>
                            <BonusCurrentBalance title="Bonus" list={dataToRender ? dataToRender.bonus : []} />
                        </Grid>
                    </Grid>

                    <Grid xs={12} md={4} lg={4}>
                        <AppCurrentLevels
                            title="Mis Ganancias"
                            chart={{
                                series: dataToRender ? dataToRender.myAppLevels : [],
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} md={12}>
                    <Card
                        sx={{
                            mb: { xs: 3, md: 5 },
                        }}
                    >
                        <Scrollbar>
                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                                sx={{ py: 2 }}
                            >
                                {
                                    dataToRender && dataToRender.levelsDetail.map((level, index) => (
                                        <LevelAnalytic
                                            icon={`healthicons:${level.nivel}-negative`}
                                            color={getColor(index)}
                                            data={level}
                                            handleViewDetail={handleViewDetail}
                                        />
                                    ))
                                }
                            </Stack>
                        </Scrollbar>
                    </Card>
                </Grid>
            </Grid>


            <Dialog
                fullWidth
                maxWidth="lg"

                open={openDetailDialog.open}
                onClose={openDetailDialog.onFalse}
            >
                <DialogContent>
                  <LevelDetail
                      dataDetail={dataToRender?.dataJson.red}
                      level={openDetailDialog.data}
                      periodoId={periodIdToUse}
                      {...(code && {code})}
                  />
                </DialogContent>
            </Dialog>

        </Container>
    );
}
